import modalCSS from '../assets/modal.scss';
import modalHTML from '../assets/modal.html';
import bbcCookies from '@bbc/iplayer-web-cookies';

var COOKIE_NAME = 'ckps_iplayer_unsupported';
var SIX_WEEKS = 6 * 7;

var uaStrings = {
  ie: {
    title: 'Your browser is no longer supported',
    desc: 'You may still be able to use BBC iPlayer but it will work much better if you use a more modern browser.',
    link: 'https://www.bbc.co.uk/iplayer/help/troubleshooting/web/supportedbrowsers_OS'
  },
  ios: {
    title: 'Your operating system is no longer supported',
    desc: 'You may still be able to use BBC iPlayer but it will work much better if you use a more modern operating system.',
    link: 'https://www.bbc.co.uk/iplayer/help/troubleshooting/mobile-tablet/ios-5-6-web'
  },
  windowsPhone: {
    title: 'Please update to Windows 10',
    desc: 'To use iPlayer, you’ll need to update to Windows 10. You can then watch with the Edge browser by visiting bbc.co.uk/iplayer',
    link: 'https://www.bbc.co.uk/iplayer/help/win_phone_app_closure'
  }
};

function setCookie() {
  var expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + SIX_WEEKS);
  var cookieOptions = {
    path: '/',
    expires: expiryDate,
    domain: '.bbc.co.uk'
  };
  bbcCookies.set({
    cookie: COOKIE_NAME,
    value: 'off',
    options: cookieOptions
  });
}

function bindDismissListener(tviplayer) {
  var modalAndBackground = tviplayer.querySelectorAll('.ipw-unsupported')[0];
  var dismissButton = modalAndBackground.querySelectorAll(
    '.ipw-unsupported__modal__dismiss'
  )[0];

  dismissButton.addEventListener('click', function () {
    modalAndBackground.parentNode.removeChild(modalAndBackground);
    document.body.classList.remove('ipw-unsupported-no-scroll');
    setCookie();
  });
}

function showModal() {
  var style = document.createElement('style');
  style.id = 'ipw-unsupported-styles';
  style.innerHTML = modalCSS;
  document.head.appendChild(style);

  var browserType = document.head
    .querySelector('script[data-browser]')
    .getAttribute('data-browser');
  var stringsForBrowser = uaStrings[browserType];
  var tviplayer = document.querySelector('#tviplayer');
  var newDiv = document.createElement('div');

  newDiv.innerHTML = modalHTML
    .replace('{ipw-unsupported-title-text}', stringsForBrowser.title)
    .replace('{ipw-unsupported-desc-text}', stringsForBrowser.desc)
    .replace('{ipw-unsupported-link-text}', stringsForBrowser.link);

  var modalElement = newDiv.firstChild;

  tviplayer.insertBefore(modalElement, tviplayer.childNodes[0]);

  document.body.classList.add('ipw-unsupported-no-scroll');

  bindDismissListener(tviplayer);
  var modal = tviplayer.querySelectorAll('.ipw-unsupported__modal')[0];

  setTimeout(function () {
    modal.focus();
  }, 1000);
}

if (!bbcCookies.get(COOKIE_NAME)) {
  showModal();
}
